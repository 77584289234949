/**
 * Application core.
 */

import Emitter from 'cjs-emitter';
import route from 'page';

import HttpError from './exceptions/http.error.js';

import sleep from 'server/utils/sleep.js';
import prepareQuery from './tools/query.generator.js';

import errors from 'server/errors.js';
import {API_BASE_PATH_URL} from './constants/general.js';
import appConfig from './config.js';


const app = new Emitter();
const requestMap = new Map();
const LOGOUT_KEY = 'cms:logout';
const SERVER_PING_INTERVAL_MS = 5000;

const logout = async () => window.fetch(`${API_BASE_PATH_URL}auth/logout`, {method: 'POST'});


app.config = appConfig;
app.route = route;
app.currentRoutePath = null;


/**
 * Sugar wrapper around native `fetch`.
 *
 * @param {string} url - base url for request
 * @param {object} [config={}] - native fetch configuration
 * @param {object} [params=null] - URL parameters
 * @param {string} [uid=null] - unique identifier used to manage abort signals for fetch
 * @param {number} [timeout=null] - debounce time in milliseconds
 *
 * @returns {object} API response
 *
 * @example
 * fetch('api/endpoints', {method: 'GET'}, {userId:3, typeId:4, meter.resourceTypeId: 2, with: ['users', 'meters']})
 */
export const fetch = async ( url, config = {}, params = null, uid = null, timeout = null ) => {
    if ( uid ) {
        const requestData = requestMap.get(uid);

        if ( requestData?.controller ) {
            requestData.controller.abort();
        }

        const controller = new AbortController();

        requestMap.set(uid, {controller});
        config.signal = controller.signal;

        if ( requestData?.timeoutId ) {
            clearTimeout(requestData.timeoutId);
        }

        if ( timeout !== null ) {
            const timeoutPromise = new Promise(resolve => {
                const timeoutId = setTimeout(() => resolve(true), timeout);

                requestMap.set(uid, {...requestMap.get(uid), timeoutId});
            });

            const isTimeoutElapsed = await timeoutPromise;

            if ( !isTimeoutElapsed ) {
                return undefined;
            }
        }
    }

    config.headers ??= {};
    config.headers['Content-type'] ??= 'application/json';
    config.headers['Accept-Language'] ??= app.config.language;

    // no body - no content-type header
    if ( !config.body ) {
        delete config.headers['Content-type'];
    }

    if ( params ) {
        url += prepareQuery(params);
    }

    while ( true ) {
        try {
            const response = await window.fetch(API_BASE_PATH_URL + url, config);
            const {status} = response;

            if ( status === 401 ) {
                console.error('auth:error');
                app.emit('auth:error');
            } else if ( status === 504 && config.method === 'GET' ) {
                throw new HttpError(status);
            }

            if ( !app.modals.connectionError.hidden ) {
                app.modals.connectionError.hidden = true;
            }

            return response;
        } catch ( error ) {
            console.error(error);

            if ( error.name === 'AbortError' || error instanceof HttpError ) {
                if ( !app.modals.connectionError.hidden ) {
                    app.modals.connectionError.hidden = true;
                }

                throw error;
            }

            if ( !app.preloader.hidden ) {
                app.preloader.hidden = true;
            }

            if ( app.modals.connectionError.hidden ) {
                app.modals.connectionError.hidden = false;
            }

            let hasConnection = false;

            while ( !hasConnection ) {
                await sleep(SERVER_PING_INTERVAL_MS);

                try {
                    const response = await window.fetch(`${API_BASE_PATH_URL}/health`, {method: 'HEAD'});

                    if ( !response.ok ) {
                        throw new Error('Server not ready.');
                    }

                    hasConnection = true;
                } catch ( error ) {
                    console.error('Ping failed:', error);
                    hasConnection = false;
                }
            }
        }
    }
};

app.authorize = async ( email, password ) => {
    const response = await window.fetch(`${API_BASE_PATH_URL}auth/login`, {
        method: 'POST',
        body: JSON.stringify({email, password}),
        headers: {
            'Content-type': 'application/json'
        }
    });

    if ( !response.ok ) {
        throw new Error((await response.json()).code);
    }

    await app.init();
};

app.logout = async ( {reload = true} = {} ) => {
    try {
        await logout();
    } catch ( error ) {
        console.error(error);
    }

    if ( reload ) {
        localStorage.setItem(LOGOUT_KEY, Date.now().toString());
        localStorage.clear();
        window.location.reload();
    }
};

app.check = () => {
    if ( !navigator.cookieEnabled ) {
        const modal = app.modals.error;

        modal.title = gettext('Error!');
        modal.description = (
            <>
                {gettext('Your browser has cookies disabled.')}
                <br/>
                {gettext('Make sure your cookies are enabled and try again.')}
            </>
        );

        modal.hidden = false;

        app.route.redirect('/auth/login');

        return false;
    }

    return true;
};

export const createRequest = ( method, url ) => {
    const request = new XMLHttpRequest();

    request.open(method, API_BASE_PATH_URL + url);
    request.withCredentials = true;

    return request;
};

app.createRequest = createRequest;

app.init = async () => {
    try {
        app.user = await (await import('./models/user.js')).default.get();
    } catch ( error ) {
        console.error(error);

        app.route.redirect('/auth/login');

        return;
    }

    if ( app.user.typeId === app.user.types.INSTALLER ) {
        await app.logout({reload: false});

        app.user = null;

        throw new Error(errors.AUTH_TOKEN_WRONG_CREDENTIALS.body.code);
    }

    app.emit('authorize');
};

window.addEventListener('storage', event => {
    if ( event.key === LOGOUT_KEY ) {
        localStorage.clear();
        window.location.reload();
    }
});


export default app;
